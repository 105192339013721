import logo from './logo.svg';
import './App.css';
import FirstFold from './containers/FirstFold';
import SecondFold from './containers/SecondFold';
import AudiencePersonas from './containers/AudiencePersonas';
import ThirdFold from './containers/ThirdFold'

function App() {
  return (
    <div className="App">
      <FirstFold/>
      <SecondFold/>
      <AudiencePersonas/>
      <ThirdFold/>
    </div>
  );
}

export default App;
