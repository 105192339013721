import React from 'react'
import audience from '../images/audience.jpeg'
import placement from '../images/placement.jpeg'
import survey from '../images/survey.jpeg'
import next from '../images/next.png'

export default function FirstFold() {

  const data = [
    {
      title: 'Bespoke Research',
      text: 'Launch an elaborate market research across gamers to identify their brand favorability, purchase intent, and other behavioral aspects. Choose from a pre-defined list of surveys or create your own with custom questions',
      image: survey
    },
    {
      title: 'Gamer Audiences',
      text: 'Create your own unique audiences from your bespoke brand research OR choose from our list of audiences based their gaming consumption & habits. ',
      image: audience
    },
    {
      title: 'Curated Supply',
      text: 'Activate your audiences on a curated list of gaming placements – web, in-app & in-game. Choose from our curated list of games based on genres & devices OR select specific game titles to advertise on.  ',
      image: placement
    }
  ]

  return (
    <div className='min-h-screen py-8 md:py-0 md:h-screen bg-cover bg-center bg-red md:bg-hero text-white relative'>
        <div class="mt-20 md:mt-0 flex flex-col justify-center items-center w-full md:h-full md:bg-black-5 md:absolute top-0 left-0">
          <h2>dentsu</h2>
          <div className='relative bg-blue w-11/12 md:w-1/3 p-5 rounded-xl mb-14 md:mt-10 md:mb-16 text-center'>
            <h1 className='lg:absolute lg-left-28 left-36 -top-10'>GAMING</h1>
            <p class="text-center md:mt-5 ">Welcome to dentsu Game Tech. Your one-stop shop for all your marketing needs for the new-age gamers. </p>
            <h4 className='my-3'>Identify > Build > Activate </h4>
             <p>Use it to solve specific challenges in the gaming vertical or construct a holistic solution from brand-specific insights to unique gamer audiences to activation via a curated gaming supply </p>
          </div>
          
          <div class="w-2/3 flex flex-col h-auto md:flex-row md:justify-between items-stretch">
              {
                data.map(i=><div className='md:h-auto my-5 md:my-0 h-96 relative'>
                  <img src={i.image} width="300px" class="md:aspect-square h-full object-cover rounded-lg grayscale"></img>
                  <div className="bg-blue-8 absolute rounded-lg top-0 left-0 w-full h-full p-5">
                    <div className='w-full h-full relative'>
                      <h4 className='mb-4'>{i.title}</h4>
                      <p>{i.text}</p>
                      <img src={next} className="absolute bottom-0 right-0"/>
                    </div>
                  </div>
                </div>)
              }
          </div>
        </div>
    </div>
  )
}
