import React, { useState, useEffect, useMemo } from "react";
import arcade1 from '../images/arcade/arcade1.jpg'
import arcade2 from '../images/arcade/arcade2.jpg'
import arcade3 from '../images/arcade/arcade3.jpg'
import arcade4 from '../images/arcade/arcade4.jpg'
import arcade5 from '../images/arcade/arcade5.jpg'
import arcade6 from '../images/arcade/arcade6.jpg'
import action1 from '../images/action/action1.jpg'
import action2 from '../images/action/action2.jpg'
import action3 from '../images/action/action3.jpg'
import action4 from '../images/action/action4.jpg'
import puzzle1 from '../images/puzzle/puzzle1.jpg'
import puzzle2 from '../images/puzzle/puzzle2.jpg'
import puzzle3 from '../images/puzzle/puzzle3.jpg'
import simulation1 from '../images/simulation/simulation1.jpg'
import simulation2 from '../images/simulation/simulation2.jpg'
import simulation3 from '../images/simulation/simulation3.jpg'
import simulation4 from '../images/simulation/simulation4.jpg'
import simulation5 from '../images/simulation/simulation5.webp'
import simulation6 from '../images/simulation/simulation6.jpg'
import rpg1 from '../images/rpg/rpg1.jpg'
import rpg2 from '../images/rpg/rpg2.jpg'
import rpg3 from '../images/rpg/rpg3.jpg'
import rpg4 from '../images/rpg/rpg4.jpeg'
import rpg5 from '../images/rpg/rpg5.jpg'
import sports1 from '../images/sports/sports1.jpg'
import sports2 from '../images/sports/sports2.jpg'
import sports3 from '../images/sports/sports3.jpg'
import sports4 from '../images/sports/sports4.jpg'
import beatup1 from '../images/beatemup/beatup1.jpeg'
import beatup2 from '../images/beatemup/beatup2.jpeg'
import beatup3 from '../images/beatemup/beatup3.jpg'
import shooters1 from '../images/shooters/shooters1.jpg'
import shooters2 from '../images/shooters/shooters2.jpg'
import shooters3 from '../images/shooters/shooters3.jpg'
import shooters4 from '../images/shooters/shooters4.jpg'
import word1 from '../images/word/word1.jpg'
import word2 from '../images/word/word2.jpg'
import word3 from '../images/word/word3.jpg'
import mmorpg1 from '../images/mmorpg/mmorpg1.jpg'
import mmorpg2 from '../images/mmorpg/mmorpg2.jpg'
import mmorpg3 from '../images/mmorpg/mmorpg3.jpg'
import moba1 from '../images/moba/moba1.webp'
import moba2 from '../images/moba/moba2.webp'
import moba3 from '../images/moba/moba3.jpg'
import moba4 from '../images/moba/moba4.jpg'

import { GoGraph } from 'react-icons/go'

function ThirdFold() {

    const [selectedOption, setSelectedOption] = useState(''); // used for selection of categories

    const [selectedPlacement, setSelectedPlacement] = useState("All"); // for selection of placements

    const [selectedImage, setSelectedImage] = useState([]); // for category images

    // for functional search bar
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResult, setSearchResult] = useState([]);

    const data = useMemo(() => [
        {
            id: 1,
            title: 'Arcade Games',
            categories: [
                { id: 1, src: "arcade1", type: "In-app" },
                { id: 2, src: "arcade2", type: "In-game" },
                { id: 3, src: "arcade3", type: "Web" },
                { id: 4, src: "arcade4", type: "In-game" },
                { id: 5, src: "arcade5", type: "In-app" },
                { id: 6, src: "arcade6", type: "Web" },
            ],
        },
        {
            id: 2,
            title: 'Action Games',
            categories: [
                { id: 7, src: "action1", type: "In-app" },
                { id: 8, src: "action2", type: "Web" },
                { id: 9, src: "action3", type: "In-game" },
                { id: 10, src: "action4", type: "Web" },
            ],
        },
        {
            id: 3,
            title: 'Puzzle Games',
            categories: [
                { id: 11, src: "puzzle1", type: "In-app" },
                { id: 12, src: "puzzle2", type: "In-game" },
                { id: 13, src: "puzzle3", type: "Web" },
            ],
        },
        {
            id: 4,
            title: 'Simulation',
            categories: [
                { id: 14, src: "simulation1", type: "In-app" },
                { id: 15, src: "simulation2", type: "In-game" },
                { id: 16, src: "simulation3", type: "Web" },
                { id: 17, src: "simulation4", type: "In-app" },
                { id: 18, src: "simulation5", type: "In-game" },
                { id: 19, src: "simulation6", type: "Web" },
            ],
        },
        {
            id: 5,
            title: 'RPG',
            categories: [
                { id: 20, src: "rpg1", type: "In-app" },
                { id: 21, src: "rpg2", type: "In-game" },
                { id: 22, src: "rpg3", type: "In-app" },
                { id: 23, src: "rpg4", type: "In-game" },
                { id: 24, src: "rpg5", type: "In-app" },
            ],
        },
        {
            id: 6,
            title: 'Sports',
            categories: [
                { id: 25, src: "sports1", type: "In-app" },
                { id: 26, src: "sports2", type: "In-game" },
                { id: 27, src: "sports3", type: "Web" },
                { id: 28, src: "sports4", type: "In-app" },
            ],
        },
        {
            id: 7,
            title: 'MMORPG',
            categories: [
                { id: 29, src: "mmorpg1", type: "In-app" },
                { id: 30, src: "mmorpg2", type: "In-game" },
                { id: 31, src: "mmorpg3", type: "Web" },
            ],
        },
        {
            id: 8,
            title: 'MOBA',
            categories: [
                { id: 32, src: "moba1", type: "In-app" },
                { id: 33, src: "moba2", type: "In-game" },
                { id: 34, src: "moba3", type: "Web" },
                { id: 35, src: "moba4", type: "In-app" },
            ],
        },
        {
            id: 9,
            title: 'Beat’em Up',
            categories: [
                { id: 36, src: "beatup1", type: "In-app" },
                { id: 37, src: "beatup2", type: "In-game" },
                { id: 38, src: "beatup3", type: "In-app" },
            ],
        },
        {
            id: 10,
            title: 'Shooters',
            categories: [
                { id: 39, src: "shooters1", type: "In-app" },
                { id: 40, src: "shooters2", type: "In-game" },
                { id: 41, src: "shooters3", type: "Web" },
                { id: 42, src: "shooters4", type: "Web" },
            ],
        },
        {
            id: 11,
            title: 'Word',
            categories: [
                { id: 43, src: "word1", type: "In-app" },
                { id: 44, src: "word2", type: "In-game" },
                { id: 45, src: "word3", type: "In-app" },
            ],
        }
    ], []) 

    const category_map = {
        arcade1,
        arcade2,
        arcade3,
        arcade4,
        arcade5,
        arcade6,
        action1,
        action2,
        action3,
        action4,
        puzzle1,
        puzzle2,
        puzzle3,
        simulation1,
        simulation2,
        simulation3,
        simulation4,
        simulation5,
        simulation6,
        rpg1,
        rpg2,
        rpg3,
        rpg4,
        rpg5,
        sports1,
        sports2,
        sports3,
        sports4,
        beatup1,
        beatup2,
        beatup3,
        shooters1,
        shooters2,
        shooters3,
        shooters4,
        word1,
        word2,
        word3,
        mmorpg1,
        mmorpg2,
        mmorpg3,
        moba1,
        moba2,
        moba3,
        moba4
    }

    const placement = ['All', 'In-app', 'In-game', 'Web']

    // used for category section
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const selectedData = data.find(item => item.title === selectedOption);


    // used for placement selection
    const handleChange = (e) => {
        setSelectedPlacement(e.target.value);
    };

    // to display the selected image in game selected section
    const handleImageClick = (category) => {
        if (selectedImage.includes(category)) {
            setSelectedImage(selectedImage.filter(c => c !== category));
        } else {
            setSelectedImage([...selectedImage, category]);
        }
    }

    // For search bar
    useEffect(() => {
        const filteredData = data.flatMap((d) => d.categories)
            .filter((c) => c.type === selectedPlacement || selectedPlacement === 'All')
            .filter((c) => c.src.toLowerCase().includes(searchQuery.toLowerCase()));
        setSearchResult(filteredData);
    }, [data, searchQuery, selectedPlacement]);

    

    return (
        <div className='min-h-screen py-8 md:py-0 bg-center md:bg-hero bg-red text-white relative'>
            <div className='mt-20 md:mt-0 flex flex-col justify-center items-center md:w-full md:h-full md:bg-black-5'>
                <div className='md:w-2/4 h-2/4'>
                    <h2 className='title text-center my-5 py-5'>Platform</h2>
                    <h4 className="break-word text-center">
                        Select an app/platform to activate your audience
                    </h4>
                </div>

                <div className="w-2/3 relative flex justify-around my-5">
                    <form class="group relative" style={{ width: '35%' }}>
                        <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500" aria-hidden="true">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                        </svg>
                        
                        <input
                            className="focus:ring-2 focus:ring-blue-500 border border-slate-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 pl-10 mt-3 ring-1 ring-slate-200 shadow-sm bg-blue"
                            type="text"
                            aria-label="Search"
                            placeholder="Search"
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </form>

                    {/* Placement Dropdown */}
                    <div className="relative">
                        <select className="inline-flex items-center px-3 py-2 my-4 border border-gray-300 text-md leading-4 font-medium rounded-md text-gray-700 bg-blue hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                            onChange={handleChange}
                            value={selectedPlacement}
                        >
                            <option value="">Select Placement</option>
                            {placement.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Category Dropdown */}
                    <div className=''>
                        <select className="px-3 py-2 my-4 border border-gray-300 text-md leading-4 font-medium rounded-md text-gray-700 bg-blue hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                            value={selectedOption}
                            onChange={handleOptionChange}
                            style={{ fontSize: 'large' }}
                        >
                            <option value="">Select Category</option>
                            {data.map(item => (
                                <option key={item.id} value={item.title}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='flex'>
                    <div className='w-1/4 mx-auto'>
                        <div className="my-5">
                            <div className="w-auto flex justify-around bg-blue-8 border border-white rounded-xl p-5">
                                <h6 className="title"> <GoGraph /> Reach</h6>
                                <h6 className="count"> 2,30 M</h6>
                            </div>
                        </div>

                        <div>
                            <div className="w-auto bg-blue-8 border border-white rounded-xl p-5">
                                No of Games: {selectedImage.length}
                            </div>
                        </div>

                        <div>
                            <h5 className="title mt-5 mb-2">Game Selected</h5>
                            <div className="w-auto bg-blue-8 border border-white rounded-lg p-2">
                                <ul className="list-styled-none">
                                    {selectedImage.map((category) => (
                                        <li key={category}>{category}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='w-2/4 flex flex-wrap md:flex-row mx-auto mt-3'>
                        {searchQuery ? (
                            searchResult.map((image) => (
                                <div className='w-1/4 p-2 text-center' onClick={() => handleImageClick(image.src)}>
                                    <div className={`bg-blue-8 ${selectedImage.includes(image.src) ? 'border-4 border-green' : 'border border-white'} rounded-xl p-2`}>
                                        <div className='flex justify-evenly'>
                                            <img
                                                key={image.id}
                                                src={category_map[image.src]}
                                                alt={image.src}
                                                width="300px"
                                                className={`md:aspect-square h-full object-cover rounded-lg ${selectedImage.includes(image.src) ? '' : 'grayscale'}`}
                                            />
                                        </div>
                                    </div>
                                    {image.src}
                                </div>
                            ))
                        ) : selectedData ? (
                            selectedData.categories.map((image) => (
                                <div className='w-1/5 p-2 text-center'>
                                    <div className='bg-blue-8 border border-white rounded-xl p-2'>
                                        <div className='flex justify-evenly'>
                                            <img
                                                src={category_map[image.src]}
                                                width="300px"
                                                className="md:aspect-square h-full object-cover rounded-lg grayscale"
                                                alt="category_images"
                                            />
                                        </div>
                                    </div>
                                    {image.src}
                                </div>
                            ))
                        ) : (
                            data.flatMap((d) => d.categories)
                                .filter((c) => c.type === selectedPlacement || selectedPlacement === 'All')
                                .map((c) => (
                                    <div className='w-1/5 p-2 text-center' onClick={() => handleImageClick(c.src)}>
                                        <div className={`bg-blue-8 ${selectedImage.includes(c.src) ? 'border-4 border-green' : 'border border-white'} rounded-xl p-2`}>
                                            <div className='flex justify-evenly'>
                                                <img
                                                    key={c.id}
                                                    src={category_map[c.src]}
                                                    alt={c.src}
                                                    width="300px"
                                                    className={`md:aspect-square h-full object-cover rounded-lg ${selectedImage.includes(c.src) ? '' : 'grayscale'}`}
                                                />
                                            </div>
                                        </div>
                                        {c.src}
                                    </div>
                                ))
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThirdFold;

