import React, { useState } from 'react';
import { GiAges, GiBullseye, GiMale, GiFemale } from "react-icons/gi";
import { FaDesktop, FaPlusCircle, FaInfoCircle, FaCheckCircle, FaMoneyBillWave } from "react-icons/fa";
import Action from '../images/action.png';
import Puzzle from '../images/puzzle.png';
import Shooters from '../images/shooter.png';
import Sports from '../images/sports.png';
import MOBA from '../images/moba.png';
import MMORPG from '../images/MMORPG.png';
import JRPG from '../images/J RPG-games.png';
import RPG from '../images/RPG-games.png';
import TileMatching from '../images/tile-matching.png';
import Word from '../images/wordtrivia.png';
import BeatemUp from '../images/Beatem Up.png';
import win from '../images/win.png';
import hype from '../images/hype.png';
import immersion from '../images/immersion.png';
import party from '../images/party.png';
import downtime from '../images/downtime.png';
import revive from '../images/revive.png'


function AudiencePersonas() {

    const data = [
        {
            id: 1,
            title: 'For The Win',
            description: 'Hardcore gamers that particpipate in competition',
            avatar: win,
            category: ['MOBA', 'Shooters', 'MMORPG'],
            details: {
                // min_age: 26,
                // max_age: 41,
                generation: 'Gen Z',
                income: 'High',
                // genre: 'Shooter',
                device: 'PC',
                goal: 'To compete',
                discussion: [
                    'Participates in eSports',
                    'Likes social gatherings',
                    'Foodie',
                    'Follows celebrity News'
                ],
            },
            audience_size: {
                male: '66%',
                female: '34%',
                overall: '5%'
            }
        },
        {
            id: 2,
            title: 'For The Hype',
            description: 'Passionate gamers who love challenges',
            avatar: hype,
            category: ['MOBA', 'BeatemUp', 'JRPG'],
            details: {
                // min_age: 26,
                // max_age: 41,
                generation: 'Millenials',
                income: 'High',
                // genre: 'Sports',
                device: 'Mutiple devices',
                goal: 'To challenge',
                discussion: [
                    'Updated with latest gaming trends',
                    'Concerned obout personal brand image',
                    'Avid investor',
                    'Likes luxury Goods'
                ],
            },
            audience_size: {
                male: '73%',
                female: '27%',
                overall: '17%'
            }
        },
        {
            id: 3,
            title: 'For The Immersion',
            description: 'Gamers engaged with streams & new experiences',
            avatar: immersion,
            category: ['Shooters', 'Action', 'RPG'],
            details: {
                // min_age: 26,
                // max_age: 41,
                generation: 'Gen Z',
                income: 'Low',
                // genre: 'Moba',
                device: 'Console',
                goal: 'To Escape',
                discussion: [
                    'Enjoys game streams',
                    'Influenced by others',
                    'Sports lover',
                    'Interested in Technology'
                ],
            },
            audience_size: {
                male: '57%',
                female: '43%',
                overall: '16%'
            }
        },
        {
            id: 4,
            title: 'For The Party',
            description: 'Gamers who play socially with friends & family',
            avatar: party,
            category: ['Sports', 'Puzzle', 'Action'],
            details: {
                // min_age: 26,
                // max_age: 41,
                generation: 'Millenials',
                income: 'Medium',
                // genre: 'Puzzle',
                device: 'Console/Mobile',
                goal: 'To Socialize',
                discussion: [
                    'Plays games while watching TV',
                    'Energtic personality',
                    'Family person',
                    'Loves to travel'
                ],
            },
            audience_size: {
                male: '52%',
                female: '48%',
                overall: '16%'
            }
        },
        {
            id: 5,
            title: 'For The Downtime',
            description: 'Casual gamers that play for fun',
            avatar: downtime,
            category: ['Word', 'TileMatching', 'Action'],
            details: {
                // min_age: 26,
                // max_age: 41,
                generation: 'Gen X',
                income: 'Medium',
                // genre: 'Openworld',
                device: 'Mobile',
                goal: 'To Pass Time',
                discussion: [
                    'Moderate interest in games',
                    'Helpful to others',
                    'Enjoys Cooking',
                    'Follows the news'
                ],
            },
            audience_size: {
                male: '37%',
                female: '63%',
                overall: '30%'
            }
        },
        {
            id: 6,
            title: 'For The Revive',
            description: 'Occassional gamers looking to unwind',
            avatar: revive,
            category: ['Puzzle', 'Word', 'TileMatching'],
            details: {
                // min_age: 26,
                // max_age: 41,
                generation: 'Baby Boomers',
                income: 'High',
                // genre: 'Adventure',
                device: 'Mobile',
                goal: 'To Relax',
                discussion: [
                    'Occasionally plays games',
                    'Learns new things',
                    'Watches - TV shows & movies',
                    'Environmentalist'
                ],
            },
            audience_size: {
                male: '57%',
                female: '43%',
                overall: '16%'
            }
        }
    ]

    // icon link
    const category_mapping = {
        Shooters,
        Action,
        Sports,
        MOBA,
        Puzzle,
        TileMatching,
        Word,
        MMORPG,
        JRPG,
        RPG,
        BeatemUp
    }

    const [selectedPersonas, setSelectedPersonas] = useState([]);

    const handlePlusClick = (title) => {
        if (selectedPersonas.includes(title)) {
            setSelectedPersonas(selectedPersonas.filter((persona) => persona !== title));
        } else {
            setSelectedPersonas([...selectedPersonas, title]);
        }
    };

    return (
        <div className='min-h-screen py-8 md:py-0 bg-center md:bg-hero bg-red text-white'>
            <h3 className='title text-center mb-3'>Audience - The Six Gaming Personas</h3>
            <p className="break-word text-center">
                The 6 personas based on dentsu's For The Game study has already been made into addressable segments -
            </p>

            <div className="w-3/4 flex flex-wrap md:flex-row mx-auto mt-3">
                {data.map((i) => {
                    return (
                        <>
                        <div className="w-1/3 p-5" key={i.id} onClick={() => handlePlusClick(i.title)}>
                            <div className={`bg-blue-8 border-4 border-green rounded-xl p-5 
                            ${selectedPersonas.includes(i.title) ? 'border-4 border-green' : 'border border-white'}`}>
                                <div className='flex justify-between'>
                                    <div className='info'>
                                        <FaInfoCircle style={{ width: '20px', height: '30px' }} />
                                    </div>
                                    <div className='plus'>
                                        {selectedPersonas.includes(i.title) ? (
                                            <FaCheckCircle style={{ width: "20px", height: "30px" }} />
                                        ) : (
                                            <FaPlusCircle style={{ width: "20px", height: "30px" }} />
                                        )}
                                    </div>
                                </div>

                                <div class="text-center">
                                    <img src={i.avatar} class="rounded-full w-24 mb-2 mx-auto" alt="Avatar" />
                                    <h4 className='mb-2'>{i.title}</h4>
                                    <p className='mb-2'>{i.description}</p>
                                    <hr />
                                    <div className='flex justify-evenly'>
                                        {i.category.map(items => {
                                            return (
                                                <div>
                                                    <img src={category_mapping[items]} class="rounded-full w-10 my-2 mx-auto" alt="category" />
                                                    {items}
                                                </div>

                                            );
                                        })}
                                    </div>
                                    <hr />
                                </div>

                                <div className='flex justify-evenly'>
                                    <div className='w-16 rounded-full bg-red my-3'>
                                        <h6 className='text-md text-center my-4'>{i.audience_size.overall}</h6>
                                    </div>
                                    <div className='my-3'>
                                        <GiMale style={{ width: '37px', height: '30px' }} />
                                        <h6 className='text-md'>{i.audience_size.male}</h6>
                                    </div>
                                    <div className='my-3'>
                                        <GiFemale style={{ width: '37px', height: '30px' }} />
                                        <h6 className='text-md'>{i.audience_size.female}</h6>
                                    </div>
                                </div>

                                <div>
                                    <h6 className='mb-2 text-center'>Persona</h6>
                                    <div className='flex border-dashed border-2 border-white mx-auto' style={{ fontSize: 'medium' }}>
                                        <ul class="w-2/3">
                                            <li>
                                                <GiAges /> {i.details.generation}
                                            </li>
                                            <li>
                                                <FaDesktop /> {i.details.device}
                                            </li>
                                        </ul>
                                        <ul class="w-1/2">
                                            <li>
                                                <FaMoneyBillWave /> {i.details.income}
                                            </li>
                                            <li>
                                                <GiBullseye /> {i.details.goal}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div>
                                    <h6 className='my-2 text-center'>Talk About</h6>
                                    <div className='border-dashed border-2 border-white w-4/5 mx-auto'>
                                        <ul class="list-disc list-inside" style={{ fontSize: 'small' }}>
                                            {i.details.discussion.map(j => {
                                                return (
                                                    <li>
                                                        {j}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    );
                })}
            </div>
        </div>
    );
}

export default AudiencePersonas;