import React, { useState } from "react";
import { FaCheckCircle, FaPlusCircle } from "react-icons/fa";  
import gameVideo from '../video/Gaming.mp4'

function SecondFold() {

    const [selectedCards, setSelectedCards] = useState([]);

    const handleCardClick = (index) => {
        // toggle the card's selection state
        if (selectedCards.includes(index)) {
          setSelectedCards(selectedCards.filter((i) => i !== index));
        } else {
          setSelectedCards([...selectedCards, index]);
        }
      };

    return (
            <div className='min-h-screen py-8 md:py-0 md:h-screen bg-center md:bg-hero bg-red text-white'>
                <div className="mt-20 md:mt-0 flex flex-col justify-center items-center md:w-full md:h-full bg-black-5">
                
                    <div className='md:w-2/4 h-2/4 relative'>
                        <div className='absolute z-0 md:w-2/4 rounded-xl' style={{ right:'3%', top:'9%'}}>
                            <div className="flex align-items-center justify-content-center ms-0 ms-sm-4">
                                <video autoPlay={"true"} loop muted width="80%" className='rounded-xl'>
                                    <source src={gameVideo} type="video/mp4"/>
                                </video>
                            </div>
                        </div>

                        <div className='absolute bg-blue md:w-1/2 p-5 z-10 rounded-xl' style={{ top:'6%'}}>
                            <h2 className='title mt-4'>Bespoke Research</h2>
                            <p className='mt-5'>Launch surveys in a few minutes leveraging our proprietary panel survey and draw unique consumer & brand insights.</p>
                        </div>
                    </div>
                    
                    <h4 className='text-center mb-8'>Select one of the pre-defined surveys or create your own with custom questions.</h4>
                    <div class="w-2/4 flex justify-center -space-x-3 font-mono text-white text-sm font-bold leading-6">
                        <div onClick={() => handleCardClick(0)} className={`card rounded-lg my-5 p-5 md:my-0 w-56 aspect-square items-center justify-center align-center bg-blue shadow-lg ring-2 ring-white z-40 dark:ring-slate-900 ${selectedCards.includes(0) ? 'ring-4 ring-green' : ''}`}>
                            {selectedCards.includes(0) ? <FaCheckCircle style={{ width: '20px', color: 'green' }} /> : <FaPlusCircle style={{ width: '20px' }} />}
                            <h4 className='mb-4'>Familarity</h4>
                        </div>
                        <div onClick={() => handleCardClick(1)} className={`card rounded-lg my-5 p-5 md:my-0 w-56 aspect-square items-center justify-center align-center bg-blue shadow-lg ring-2 ring-white z-30 dark:ring-slate-900 ${selectedCards.includes(1) ? 'ring-4 ring-green' : ''}`}>
                            {selectedCards.includes(1) ? <FaCheckCircle style={{ width: '20px', color: 'green' }} /> : <FaPlusCircle style={{ width: '20px' }} />}
                            <h4 className='mb-4'>Likeability</h4>
                        </div>
                        <div onClick={() => handleCardClick(2)} className={`card rounded-lg my-5 p-5 md:my-0 w-56 aspect-square items-center justify-center align-center bg-blue shadow-lg ring-2 ring-white z-20 dark:ring-slate-900 ${selectedCards.includes(2) ? 'ring-4 ring-green' : ''}`}>
                            {selectedCards.includes(2) ? <FaCheckCircle style={{ width: '20px', color: 'green' }} /> : <FaPlusCircle style={{ width: '20px' }} />}
                            <h4 className='mb-4'>Purchase Intent</h4>
                        </div>
                        <div onClick={() => handleCardClick(3)} className={`card rounded-lg my-5 p-5 md:my-0 w-56 aspect-square items-center justify-center align-center bg-blue shadow-lg ring-2 ring-white z-30 dark:ring-slate-900 ${selectedCards.includes(3) ? 'ring-4 ring-green' : ''}`}>
                            {selectedCards.includes(3) ? <FaCheckCircle style={{ width: '20px', color: 'green' }} /> : <FaPlusCircle style={{ width: '20px' }} />}
                            <h4 className='mb-4'>Message Association</h4>
                        </div>
                        <div onClick={() => handleCardClick(4)} className={`card rounded-lg my-5 p-5 md:my-0 w-56 aspect-square items-center justify-center align-center bg-blue shadow-lg ring-2 ring-white z-20 dark:ring-slate-900 ${selectedCards.includes(4) ? 'ring-4 ring-green' : ''}`}>
                            {selectedCards.includes(4) ? <FaCheckCircle style={{ width: '20px', color: 'green' }} /> : <FaPlusCircle style={{ width: '20px' }} />}
                            <h4 className='mb-4'>Custom</h4>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default SecondFold;